@import './fonts.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f8;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.content {
  flex: 1 0 auto;
  padding: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sentinel {
  font-family: Sentinel;
}
.nudista {
  font-family: Nudista;
}

// ---------------------------------- CALENDAR STYLING ----------------------------------- //

//styling background of weekly header
.rbc-header {
  // background-color: #597f54;
  // color: white;
  span {
    font-weight: 400;
  }
}
//getting rid of light blue background for today
.rbc-today.rbc-header,
.rbc-today.rbc-time-column,
.rbc-day-bg.rbc-today {
  background-color: white;
}

//day of week color
.rbc-header a div:nth-child(1) {
  color: #70757a;
}

//date of week color
.rbc-header a div:nth-child(2) {
  color: #3c4043;
}

//today's date of week color
.rbc-today.rbc-header a div:nth-child(1) {
  color: #1a73e8;
}

//styling background of weekly header for todays date
.rbc-today.rbc-header a div:nth-child(2) {
  background-color: #1a73e8;
  color: white;
  border-radius: 100%;
}

//styling of monthly header
.rbc-month-header > .rbc-header {
  padding: 10px 0;
  span {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.8px;
    color: #70757a;
  }
}

//styling all the times in the weekly/daily calendar views
.rbc-time-gutter > .rbc-timeslot-group,
.rbc-label.rbc-time-header-gutter {
  background-color: #ebf0ea;
}

//styling date title
.rbc-toolbar-label {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #3e623a;
}

//styling background in button group
.rbc-btn-group {
  background-color: #156570;
  color: white;
  overflow: hidden;
  border-radius: 4px;
}

//Styling font in button group
.rbc-toolbar > .rbc-btn-group > button {
  border: none;
  border: 1px solid #adadad;
  color: white;
  font-family: 'Roboto';
  font-weight: 300;
}

.rbc-toolbar > .rbc-btn-group > button.rbc-active {
  color: #333;
}

//Changing size of the time on an event
.rbc-event .rbc-event-label {
  font-size: 10px;
}

//Making thinner text for times
.rbc-label {
  font-weight: 400;
  // font-size: 14px;
  color: #70757a;
  font-size: 11px;
}

//Thinner numbers on month calendar
.rbc-row .rbc-date-cell {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  display: inline-block;
  white-space: nowrap;
  width: max-content;
  color: #3c4043;
  line-height: 16px;
  text-align: center;
  padding-right: 0;
}

//Making it so weekly date row isn't cut off
.rbc-time-view .rbc-row {
  // min-height: inherit !important;
}

.rbc-time-view .rbc-time-header-content .rbc-allday-cell {
  height: auto;
}

//Necessary to make all day slot still visible
.rbc-row-content {
  min-height: 20px;
}

.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  line-height: 40px;
}

.rbc-now.rbc-date-cell > a {
  background-color: #1a73e8;
  color: white;
  border-radius: 100%;
  padding: 5px;
  line-height: 40px;
}

.rbc-show-more {
  color: #3c4043;
}

.rbc-agenda-empty {
  text-align: center;
}

.calendarTitle,
.meetWithNwcu {
  font-family: Sentinel, Roboto;
  font-size: 29px;
  font-weight: 700;
}
